import { autoinject, bindable, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import {
  CreateUpdateMooringFrameSalesOrderLine,
  CreateUpdateMooringSalesOrder,
  MooringArticleType,
  MooringCertificateListItem,
  MooringFrameOrderCateogry,
  MooringProductType,
  MooringSalesOrderType,
  SalesOrderTypeEnum,
  UnitOfMeasure,
} from 'models';
import { Models } from 'models/core';
import {
  ErrorService,
  MooringArticleCategoryService,
  MooringArticleService,
  MooringArticleTypeService,
  MooringCertificateService,
  MooringFrameOrderCategoryService,
  MooringProductTypeService,
  MooringSalesOrderTypeService,
} from 'services';
import { ContactService } from 'services/contact-service';
import { CustomerService } from 'services/customer-service';
import { SiteService } from 'services/site-service';
import { SearchResult } from './search-box';
import './salesorder-form.scss';
import { categoryDimensionTypes } from '../../../models';
import { SelectedItemDetails } from './search-input';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';
import { ProductService } from 'services/product-service';
import { DialogService } from 'aurelia-dialog';
import { CustomerDialog } from 'features/contact/customer-dialog';
import { MooringSalesOrderService } from 'services/mooring-sales-order-service';

type Product = {
  Id: number;
  ArticleNo: string;
  Name: string;
};

@autoinject
export class SalesOrderForm {
  protected ready = false;

  @bindable
  isLocked = false;

  protected hasId = false;

  @bindable
  @observable
  readonly model: CreateUpdateMooringSalesOrder;
  modelChanged(value: CreateUpdateMooringSalesOrder) {
    this.hasId = !!value?.Id;
  }

  protected form?: CreateUpdateMooringSalesOrder = {} as CreateUpdateMooringSalesOrder;

  protected certificates: {
    [key: number]: {
      Name: string;
      Id: number;
      ArticleProductId: number;
      ArticleNumber: string;
      Supplier: string;
    };
  };

  protected get allCertificates() {
    return JSON.parse(JSON.stringify(this.certificates));
  }

  @bindable readonly onSave: (data: { data: CreateUpdateMooringSalesOrder }) => void;
  @bindable readonly onCancel: () => void;
  @bindable readonly isSaving = false;

  protected contactEmail: string = '';
  protected customers: Models.Customer[] = [];
  protected sites: Models.Site[] = [];
  protected contacts: Models.Contact[] = [];
  protected orderTypes: MooringSalesOrderType[] = [];
  protected categories: MooringFrameOrderCateogry[] = [];

  protected isStandard = true;
  protected orderNumberExists = false;

  constructor(
    protected validator: Validator,
    private customerService: CustomerService,
    private siteService: SiteService,
    private ContactService: ContactService,
    private validationController: ValidationController,
    private t: I18N,
    private errorService: ErrorService,
    private certificateService: MooringCertificateService,
    private mooringSalesOrderService: MooringSalesOrderService,
    private mooringOrderTypeService: MooringSalesOrderTypeService,
    private mooringFrameOrderCateogryService: MooringFrameOrderCategoryService,
    private mooringArticleService: MooringArticleService,
    private mooringArticleCategoryService: MooringArticleCategoryService,
    private mooringArticleTypeService: MooringArticleTypeService,
    private mooringProductTypeService: MooringProductTypeService,
    private productService: ProductService,
    private confirmation: ConfirmDialog,
    private dialogService: DialogService
  ) { }

  protected applyValidationRules() {
    ValidationRules.ensure('OrderNumber')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('CustomerId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('TypeId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .on(this.form);

    if (!this.isStandard) {
      ValidationRules.ensure('CagesX')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .min(1)
        .withMessage(this.t.tr('general.requiredField'))
        .ensure('CagesY')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .min(1)
        .withMessage(this.t.tr('general.requiredField'))
        .ensure('CageLength')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .min(1)
        .withMessage(this.t.tr('general.requiredField'))
        .ensure('CageWidth')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .min(1)
        .withMessage(this.t.tr('general.requiredField'))
        .ensure('MountedAt')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .ensure('ProductName')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .ensure('ForUseIn')
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .on(this.form.FrameOrderDetails);
    }
  }

  async attached() {
    await this.getCustomers();
    await this.getCategories();
    await this.getCertificates();
    await this.setup();
    this.applyValidationRules();
  }

  protected editCustomer() {
    if (!this.form.CustomerId) return;
    void this.dialogService
      .open({
        viewModel: CustomerDialog,
        model: {
          customerId: this.form.CustomerId,
        },
        position: () => {
          return {
            top: 0,
          };
        },
      })
      .whenClosed(() => {
        document.querySelector('html').style.overflowY = null;
        this.setCustomerId(this.form.CustomerId);
      });
  }

  protected async getOrderTypes() {
    try {
      return await this.mooringOrderTypeService.getAll();
    } catch (error) {
      this.errorService.handleError(error);
    }
    return [];
  }

  protected async checkForSalesOrderExists() {
    try {
      if (!this.form?.OrderNumber) return;
      const res = await this.mooringSalesOrderService.checkOrderNumberExists(this.form.OrderNumber);
      this.orderNumberExists = res.orderNumberExists;
      if (this.orderNumberExists) {
        this.validationController.addError(
          this.t.tr('validation.ValueAlreadyExists', { value: this.t.tr('general.orderNumber') }),
          this.form,
          'OrderNumber'
        );
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private categoryMap: Record<number, MooringFrameOrderCateogry> = {};

  private async getCategories() {
    try {
      this.categories = (await this.mooringFrameOrderCateogryService.getAll())
        .filter((x) => !x.IsDeleted)
        .sort((a, b) => a.Id - b.Id);
      this.categories.forEach((x) => (this.categoryMap[x.Id] = x));
    } catch (error) {
      this.errorService.handleError(error);
    }
    return [];
  }

  private async getCertificates() {
    try {
      const certificateIds = Array.from(new Set(this.model?.OrderLines?.map((x) => x.CertificateId) || [])).join(',');

      this.certificates = (
        await this.certificateService.getAll(
          `?$filter=deleted eq null and id in (${certificateIds})&$expand=Supplier,ArticleProduct($expand=Product)`
        )
      ).reduce((acc, x: any) => {
        acc[x.Id] = {
          Id: x.Id,
          Name: `${x.BatchNumber}${x.BatchNumberTo ? ' - ' + x.BatchNumberTo : ''}`,
          ArticleProductId: x.ArticleProductId,
          ArticleNumber: x.ArticleProduct?.Product?.ArticleNo ?? '',
          Supplier: x.Supplier?.Name ?? '',
        };
        return acc;
      }, {});
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private getBaseFrameOrderLines() {
    return this.categories.map(
      (x): CreateUpdateMooringFrameSalesOrderLine => ({
        FrameOrderCategoryId: x.Id,
        CanEditLength: false,
        SalesOrderId: undefined,
        Quantity: undefined,
        Length: undefined,
        Type: undefined,
        TotalLength: undefined,
        ProductType: undefined,
        MooringArticleCategoriType: undefined,
        Id: undefined,
        CertificateId: undefined,
        _isFirst: true,
        PrimaryDimension: undefined,
        PrimaryDimensionType: undefined,
        SecondaryDimension: undefined,
        SecondaryDimensionType: undefined,
        Dimension: undefined,
      })
    );
  }

  protected async setup() {
    this.orderTypes = await this.getOrderTypes();

    if (!this.model) {
      this.ready = true;
      this.form.TypeId = this.orderTypes.find((x) => x.Type == SalesOrderTypeEnum.Standard)?.Id;
      return;
    }

    this.isStandard = this.model.TypeId == this.orderTypes.find((x) => x.Type == SalesOrderTypeEnum.Standard)?.Id;

    this.form = JSON.parse(JSON.stringify(this.model));
    await this.resetForm();
    this.ready = true;
  }

  protected async resetForm() {
    if (this.form.CustomerId) {
      await Promise.all([this.getLocalities(), this.getContacts()]);
    }
  }

  protected async getCustomers() {
    try {
      this.customers = await this.customerService.getAll();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected setCustomerId(id: number | undefined) {
    this.form.CustomerId = id;
    if (id) {
      void this.getLocalities();
      void this.getContacts();
    }
  }

  protected async getLocalities() {
    try {
      this.sites = await this.siteService.getAll(`?$filter=deleted eq null and CustomerId eq ${this.form.CustomerId}`);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected setSiteId(id: number | undefined) {
    this.form.SiteId = id;
    if (id) {
      void this.getContacts();
    }
  }

  protected setType(typeId: number) {
    this.form.TypeId = typeId;
    this.isStandard = typeId == this.orderTypes.find((x) => x.Type == SalesOrderTypeEnum.Standard)?.Id;
    if (!this.isStandard) {
      this.form.FrameOrderLines = this.getBaseFrameOrderLines();
      if (!this.form.FrameOrderDetails) {
        this.form.FrameOrderDetails = {
          ProductName: 'Flexilink ramme komplett',
          ForUseIn: 'Rammefortøyning oppdrett',
        };
      }
    }
  }

  protected async getContacts() {
    try {
      this.contacts = await this.ContactService.getAll(
        `?$filter=deleted eq null and CustomerId eq ${this.form.CustomerId}`
      );

      this.setContactId(this.form.ContactId);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected setContactId(id: number | undefined) {
    this.form.ContactId = id;
    this.setContactEmail(id);
  }

  protected setContactEmail(contactId?: number) {
    if (!contactId) return;
    this.contactEmail = this.contacts.find((x) => x.Id == contactId)?.Email || '';
  }

  protected addFrameOrderLine(frameOrderCategoryId: number) {
    if (!this.form.OrderLines) this.form.OrderLines = [];
    let index = 0;
    let lastIndex = 0;
    for (const iterator of this.form.FrameOrderLines) {
      if (iterator.FrameOrderCategoryId == frameOrderCategoryId) {
        lastIndex = index;
      }
      index++;
    }

    this.form.FrameOrderLines = [
      ...this.form.FrameOrderLines.slice(0, lastIndex + 1),
      {
        Id: undefined,
        MooringArticleCategoriType: undefined,
        Length: undefined,
        Quantity: undefined,
        TotalLength: undefined,
        Type: undefined,
        ProductType: undefined,
        SalesOrderId: undefined,
        CertificateId: undefined,
        CanEditLength: false,
        FrameOrderCategoryId: frameOrderCategoryId,
        _isFirst: false,
        PrimaryDimension: undefined,
        PrimaryDimensionType: undefined,
        SecondaryDimension: undefined,
        SecondaryDimensionType: undefined,
        ActualBatchNumber: undefined,
        Dimension: undefined,
      },
      ...this.form.FrameOrderLines.slice(lastIndex + 1),
    ];
  }

  protected addOrderLine() {
    if (!this.form.OrderLines) this.form.OrderLines = [];
    this.form.OrderLines.push({
      SalesOrderId: undefined,
      ArticleProductId: undefined,
      ArticleNumber: undefined,
      ProductId: undefined,
      Quantity: undefined,
      CertificateId: undefined,
      Category: undefined,
      Type: undefined,
      Product: undefined,
      PrimaryDimension: undefined,
      PrimaryDimensionType: undefined,
      SecondaryDimension: undefined,
      SecondaryDimensionType: undefined,
      Dimension: undefined,
      ActualBatchNumber: undefined,
      UnitOfMeasure: UnitOfMeasure.pieces,
    });
  }

  private getDimensionString(item: MooringCertificateListItem) {
    const primaryDimensionType = categoryDimensionTypes.find((x) => x.value == item.PrimaryDimensionType);
    const secondaryDimensionType = categoryDimensionTypes.find((x) => x.value == item.SecondaryDimensionType);

    let dimension = `${item.PrimaryDimension} ${this.t.tr(primaryDimensionType?.labelShort)}`;
    if (secondaryDimensionType) {
      dimension += ` / ${item.SecondaryDimension} ${this.t.tr(secondaryDimensionType?.labelShort)}`;
    }

    return dimension;
  }

  protected async searchForCertificate(
    term: string,
    index: number,
    type: 'frame' | 'standard'
  ): Promise<SearchResult[]> {
    try {
      let result = undefined;
      if (type == 'frame') {
        result = await this.certificateService.seach(term);
      } else {
        const articleNumber = this.form.OrderLines[index].ArticleNumber
          ? this.form.OrderLines[index].ArticleNumber
          : '';
        result = articleNumber
          ? await this.certificateService.seach(term, this.form.OrderLines[index]?.ArticleNumber)
          : await this.certificateService.seach(term);
      }

      if (!result || !result.length || result === undefined) return [];

      return result.map((x) => ({
        id: x.Id,
        title: `${this.t.tr('general.article')}: ${x.ArticleNo}`,
        meta: `${this.t.tr('general.batchNumber')}: ${x.BatchNumber} ${x.BatchNumberTo ? ' - ' + x.BatchNumberTo : ''}`,
        extra: [
          `${x.CategoryName} - ${x.TypeName} - ${x.ProductTypeName} (${this.getDimensionString(x)})`,
          `${this.t.tr('general.producer')}: ${x.SupplierName?.slice(0, 30)}`,
        ],
        return: x,
      }));
    } catch (error) {
      this.errorService.handleError(error);
    }
    return [];
  }

  protected async searchForArticleNumber(term: string): Promise<SearchResult[]> {
    try {
      const result = await this.productService.search(term);
      if (!result || !result.length) return [];

      return result.map((x) => ({
        id: x.Id,
        title: `${this.t.tr('general.article')}: ${x.ArticleNo}`,
        extra: [`${this.t.tr('general.name')} : ${x.Name}`],
        return: x,
      }));
    } catch (error) {
      this.errorService.handleError(error);
    }
    return [];
  }

  protected onArticleNumberSelected(item: SearchResult, type: 'frame' | 'standad', index: number) {
    const product = item.return as Product;
    this.form.OrderLines[index].ProductId = product.Id;
    this.form.OrderLines[index].ArticleNumber = product.ArticleNo;
  }

  protected async onCertificateSelected(item: SearchResult, type: 'frame' | 'standad', index: number) {
    const certificate = await this.certificateService.get(item.id);
    const article = await this.mooringArticleService.get(certificate.ArticleProduct.MooringArticleId);

    const mooringArticleCategorie = await this.mooringArticleCategoryService.get(article.CategoryId);

    let mooringArticleType: MooringArticleType | undefined = undefined;
    if (article.TypeId) {
      mooringArticleType = await this.mooringArticleTypeService.get(article.TypeId);
    }

    let mooringProduct: MooringProductType | undefined = undefined;
    if (article.ProductTypeId) {
      mooringProduct = await this.mooringProductTypeService.get(article.ProductTypeId);
    }

    this.certificates[certificate.Id] = {
      Id: certificate.Id,
      Name: `${certificate.BatchNumber} ${certificate.BatchNumberTo ? ' - ' + certificate.BatchNumberTo : ''}`,
      ArticleProductId: certificate.ArticleProductId,
      ArticleNumber: certificate.ArticleProduct.ArticleNumber,
      Supplier: certificate.SupplierName,
    };

    const primaryDimensionType = categoryDimensionTypes.find((x) => x.value == article.PrimaryDimensionType);
    const secondaryDimensionType = categoryDimensionTypes.find((x) => x.value == article.SecondaryDimensionType);

    let dimension = '';
    if (primaryDimensionType) {
      dimension += `${article.PrimaryDimension} ${this.t.tr(primaryDimensionType?.labelShort)}`;
    }
    if (secondaryDimensionType) {
      dimension += ` / ${article.SecondaryDimension} ${this.t.tr(secondaryDimensionType?.labelShort)}`;
    }

    if (type == 'frame') {
      const canEditLength =
        mooringArticleCategorie.Name === 'tau' || mooringArticleCategorie.Name === 'kjetting' ? true : false;

      const frameOrderLine = this.form.FrameOrderLines[index];
      frameOrderLine._typeMBL = article.MBL / 1000;
      frameOrderLine.CertificateId = certificate.Id;
      frameOrderLine.MooringArticleCategoriType = mooringArticleCategorie.Name;
      frameOrderLine.Dimension = dimension;
      frameOrderLine.Type = mooringArticleType?.Name || '';
      frameOrderLine.ProductType = mooringProduct?.Name || '';
      frameOrderLine.CanEditLength = canEditLength;

      // If the certificate is not a series/interval, then the actual batch number is the same as the batch number
      if (!certificate.BatchNumberTo?.trim()) {
        frameOrderLine.ActualBatchNumber = certificate.BatchNumber;
      }
    } else {
      const orderLine = this.form.OrderLines[index];
      orderLine.ArticleProductId = certificate.ArticleProductId;
      orderLine.CertificateId = certificate.Id;
      orderLine.Category = mooringArticleCategorie.Name;
      orderLine.Type = mooringArticleType?.Name || '';
      orderLine.Product = mooringProduct?.Name || '';
      orderLine.Dimension = dimension;
      // If the certificate is not a series/interval, then the actual batch number is the same as the batch number
      if (!certificate.BatchNumberTo?.trim()) {
        orderLine.ActualBatchNumber = certificate.BatchNumber;
      }
    }

    this.startSaveTimer();
  }

  protected async getCertificateDetails(id: number): Promise<SelectedItemDetails> {
    const cert = await this.certificateService.get(id);
    return {
      id,
      title: `${cert.BatchNumber} ${cert.BatchNumberTo ? ' - ' + cert.BatchNumberTo : ''}`,
    };
  }

  protected async getArticleNumberDetails(id: number): Promise<SelectedItemDetails> {
    const product = await this.productService.get(id);
    return {
      id,
      title: `${product.ArticleNo}`,
    };
  }

  protected removeOrderLine(index: number) {
    this.form.OrderLines.splice(index, 1);
  }

  protected async removeFrameOrderLine(index: number) {
    const confirmed = await this.confirmation.confirmYesNo(
      'general.removeCertificate',
      'general.removeCertificateConfimation'
    );
    if (!confirmed) {
      return;
    }

    const currentLine = this.form.FrameOrderLines.at(index);
    const nextLine = this.form.FrameOrderLines.at(index + 1);

    const isSameCategory = nextLine?.FrameOrderCategoryId === currentLine.FrameOrderCategoryId;
    if (!currentLine._isFirst) {
      this.form.FrameOrderLines.splice(index, 1);
    } else if (!nextLine || !isSameCategory) {
      const newLine = {
        _isFirst: true,
        CanEditLength: false,
        FrameOrderCategoryId: currentLine.FrameOrderCategoryId,
      } as CreateUpdateMooringFrameSalesOrderLine;
      this.form.FrameOrderLines.splice(index, 1, newLine);
    } else {
      nextLine._isFirst = true;
      this.form.FrameOrderLines.splice(index, 1);
    }

    await this.submit();
  }

  protected isLastOfFrameOrderLine(line: CreateUpdateMooringFrameSalesOrderLine) {
    const l = this.form.FrameOrderLines.filter((x) => x.FrameOrderCategoryId == line.FrameOrderCategoryId)?.length;
    return l == 1;
  }

  private async validate() {
    this.applyValidationRules();
    const result = await this.validationController.validate({ object: this.form });
    let detailsResults = true;
    if (!this.isStandard) {
      const nested = await this.validationController.validate({ object: this.form.FrameOrderDetails });
      detailsResults = nested.valid;
    }
    return result.valid && detailsResults;
  }

  timer = 0;
  protected startSaveTimer() {
    clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      void this.submit();
    }, 2000);

    if (this.form.OrderLines && this.form.OrderLines.length) {
      const last = this.form.OrderLines[this.form.OrderLines.length - 1];
      if (last.Quantity || last.CertificateId) {
        this.addOrderLine();
      }
    }
  }

  detached() {
    clearTimeout(this.timer);
  }

  protected async submit() {
    try {
      if (!(await this.validate())) return;
      this.onSave?.({ data: this.form });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected cancel() {
    this.onCancel?.();
  }

  protected getClass(line: CreateUpdateMooringFrameSalesOrderLine, index: number) {
    if (line._isFirst) return 'top-frame zebra';
    const cl = 'top-frame-borderless';
    if (index % 2 == 0) return cl + ' zebra';
    return cl;
  }

  protected calculateTotalLength(index: number) {
    const frameLine = this.form.FrameOrderLines[index];
    if (frameLine.Length && !frameLine.Quantity) {
      frameLine.TotalLength = frameLine.Length;
    } else {
      frameLine.TotalLength = frameLine.Length * frameLine.Quantity;
    }
  }
}
